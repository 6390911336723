<template>
    <header class="header-navbar relative z-10 flex items-center h-14 lg:h-16 bg-white">
        <div class="container grid-container">
            <div class="flex items-center">
                <button class="lg:hidden w-5 mr-5 text-xl text-black focus:outline-none" @click="isMenuOpened = !isMenuOpened">
                    <i v-if="!isMenuOpened" class="fas fa-bars"></i>
                    <img v-else src="~@/static/images/icons/cross-black.svg" alt="Fermer" class="w-5">
                </button>

                <a href="https://intersport.fr" class="lg:mr-8">
                    <img src="~@/static/images/logo-desktop.svg" alt="Logo Intersport" class="hidden lg:flex w-40">
                    <img src="~@/static/images/logo-mobile.svg" alt="Logo Intersport" class="lg:hidden w-8">
                </a>

                <div
                    :class="{'header-navbar__menu--opened': isMenuOpened}"
                    class="header-navbar__menu absolute z-10 lg:relative left-0 lg:left-auto hidden lg:flex flex-col lg:flex-row lg:items-center w-full lg:w-auto bg-white border-t border-gray-300 lg:border-0"
                >
                    <a
                        v-for="(item, key) in links"
                        :key="key"
                        :href="item.link.url"
                        class="flex justify-between items-center px-4 py-4 lg:px-0 lg:mx-8 font-semibold text-base text-black uppercase border-b border-gray-100 lg:border-0"
                    >
                        {{ item.link.label }}

                        <img src="~@/static/images/icons/chevron-right-black.svg" alt="Chevron" class="w-5 lg:hidden">
                    </a>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        name: "HeaderNavBar",

        props: {
            links: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                isMenuOpened: false,
            }
        },
    }
</script>

<style scoped lang="scss">
    .header-navbar {
        &__menu {
            &--opened {
                top: 100%;

                @apply flex;
            }
        }
    }
</style>
