<template>
    <section class="video-carousel bg-cover bg-center" :class="`video-carousel--text-${textColor} ${spacings}`" :style="background">
        <div class="grid-container container">
            <div class="grid-row justify-center">
                <div class="grid-col w-full lg:w-2/3">
                    <div class="video-carousel__text lg:text-center" v-html="text"/>
                </div>
            </div>
        </div>

        <div class="px-4">
            <div class="video-carousel__main relative w-full mt-7 mx-auto">
                <button
                    v-if="media.length > 1"
                    class="video-carousel__previous-slide-container overflow-hidden absolute left-0 hidden lg:flex focus:outline-none bg-transparent border-0 p-0"
                    @click="updateSlide(currentSlideId-1)"
                >
                    <div class="video-carousel__slide w-full h-full bg-black">
                        <transition :name="transitionName">
                            <template v-for="(item, key) in media">
                                <div
                                    class="absolute inset-0 w-full h-full bg-cover bg-center"
                                    v-if="previousSlideId === key"
                                    :key="`previous-slide-${key}`"
                                    :style="`background-image: url('${item.coverImage.url}')`"
                                ></div>
                            </template>
                        </transition>
                    </div>
                </button>

                <button
                    v-if="media.length > 1"
                    class="video-carousel__next-slide-container overflow-hidden absolute right-0 hidden lg:flex focus:outline-none bg-transparent border-0 p-0"
                    @click="updateSlide(currentSlideId+1)"
                >
                    <div class="video-carousel__slide w-full h-full bg-black">
                        <transition :name="transitionName">
                            <template v-for="(item, key) in media">
                                <div
                                    class="absolute inset-0 w-full h-full bg-cover bg-center"
                                    v-if="nextSlideId === key"
                                    :key="`next-slide-${key}`"
                                    :style="`background-image: url('${item.coverImage.url}')`"
                                ></div>
                            </template>
                        </transition>
                    </div>
                </button>

                <div class="lg:px-16">
                    <div class="video-carousel__main-slide-container overflow-hidden relative mx-auto">
                        <div class="video-carousel__slide bg-black">
                            <transition :name="transitionName">
                                <template v-for="(item, key) in media">
                                    <div
                                        class="absolute inset-0 w-full h-full flex flex-col justify-end lg:justify-center items-center bg-cover bg-center p-4"
                                        v-if="currentSlideId === key"
                                        :key="`main-slide-${key}`"
                                        :style="`background-image: url('${item.coverImage.url}')`"
                                    >
                                        <button
                                            v-if="item.videoUrl || item.youtubeLink"
                                            class="video-carousel__play-button play-button"
                                            @click="openPlayer"
                                        >Play</button>

                                        <p
                                            v-if="item.title"
                                            class="font-bold text-white text-xs lg:text-base uppercase text-center mt-2 lg:mt-6"
                                        >{{ item.title }}</p>

                                        <youtube
                                            v-if="isPlayerOpened && item.youtubeLink"
                                            :video-id="youtubeVideoId(item.youtubeLink)"
                                            :player-vars="playerVars"
                                            class="absolute inset-0 w-full h-full"
                                        ></youtube>

                                        <video
                                            ref="html5Player"
                                            v-if="isPlayerOpened && item.videoUrl"
                                            :src="item.videoUrl"
                                            class="absolute inset-0 w-full h-full"
                                            controls
                                            autoplay
                                            playsinline
                                        ></video>
                                    </div>
                                </template>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="media.length > 1" class="flex justify-center items-center mt-7">
            <button
                class="video-carousel__arrow video-carousel__arrow--previous bg-cover bg-center w-6 h-6 border-0 mr-4 focus:outline-none"
                @click="updateSlide(currentSlideId-1)"
            >Précédent</button>

            <button
                v-for="(item, key) in media"
                :key="key"
                class="p-2 border-0 focus:outline-none"
                @click="updateSlide(key)"
            ><span class="video-carousel__folio-item flex w-2 h-2 rounded-full" :class="{'bg-black': currentSlideId === key, 'bg-gray-200': currentSlideId !== key}">{{ key }}</span></button>

            <button
                class="video-carousel__arrow video-carousel__arrow--next bg-cover bg-center w-6 h-6 border-0 ml-4 focus:outline-none"
                @click="updateSlide(currentSlideId+1)"
            >Suivant</button>
        </div>
    </section>
</template>

<script>
    export default {
        name: "MediaCarousel",

        props: {
            text: {
                type: String,
                default: '',
            },

            media: {
                type: Array,
                required: true,
            },

            backgroundImage: {
                type: Object,
                default: null,
            },

            backgroundColor: {
                type: String,
                default: '#ffffff',
            },

            textColor: {
                type: String,
                default: 'black',
            },
        },

        data() {
            return {
                currentSlideId: 0,
                transitionName: 'next-slide',
                isPlayerOpened: false,
                playerVars: {
                    autoplay: 1
                }
            }
        },

        computed: {
            previousSlideId() {
                if (this.currentSlideId === 0) {
                    return this.media.length - 1;
                }

                return this.currentSlideId - 1;
            },

            nextSlideId() {
                if (this.currentSlideId === this.media.length - 1) {
                    return 0;
                }

                return this.currentSlideId + 1;
            },

            spacings() {
                if (this.background) {
                    return 'py-14 lg:py-16'
                }

                return 'my-14 lg:my-16';
            },

            background() {
                let background = '';

                if (this.backgroundImage) {
                    background += `background-image: url(${this.backgroundImage.url});`;
                }

                if (this.backgroundColor && this.backgroundColor !== '#ffffff') {
                    background += `background-color: ${this.backgroundColor};`
                }

                return background;
            }
        },

        methods: {
            updateSlide(id) {
                this.isPlayerOpened = false;
                this.transitionName = id < this.currentSlideId ? 'previous-slide': 'next-slide';

                if (id === this.media.length) {
                    this.currentSlideId = 0;
                    return;
                }

                if (id < 0) {
                    this.currentSlideId = this.media.length - 1;
                    return;
                }

                this.currentSlideId = id;
            },

            youtubeVideoId(youtubeLink) {
                return this.$youtube.getIdFromUrl(youtubeLink);
            },

            openPlayer() {
                this.isPlayerOpened = true;
            },
        }
    }
</script>

<style scoped lang="scss">
    .video-carousel {
        &__text {
            /deep/h3 {
                @apply mb-3;

                @screen lg {
                    @apply mb-6;
                }
            }

            /deep/p {
                @apply text-gray-500 my-3;
            }
        }

        &--text-white {
            @apply text-white;

            /deep/p {
                @apply text-white;
            }
        }

        &__main {
            max-width: 82.875rem;
        }

        &__main-slide-container {
            max-width: 62.25rem;
        }

        &__previous-slide-container,
        &__next-slide-container {
            width: 35.375rem;
            top: 50%;
            transform: translateY(-50%);

            .video-carousel__slide {
                transition: transform 200ms;
            }

            &:hover {
                .video-carousel__slide {
                    transform: scale(1.05);
                }
            }
        }

        &__slide {
            padding-top: 56.25%
        }

        &__play-button {
            width: 4.375rem;
            height: 4.375rem;

            &::after {
                width: 1.375rem;
                height: 1.75rem;
            }

            @screen lg {
                width: 6.25rem;
                height: 6.25rem;

                &::after {
                    width: 1.875rem;
                    height: 2.5rem;
                }
            }
        }

        .previous-slide-leave-active,
        .previous-slide-enter-active,
        .next-slide-leave-active,
        .next-slide-enter-active {
            transition: 1s;
        }

        .previous-slide-leave-to,
        .next-slide-enter {
            transform: translate(100%, 0);
        }
        .next-slide-leave-to,
        .previous-slide-enter {
            transform: translate(-100%, 0);
        }

        &__folio-item {
            font-size: 0;
        }

        &__arrow {
            font-size: 0;

            &--previous {
                background-image: url('~@/static/images/icons/chevron-left-black.svg');
            }

            &--next {
                background-image: url('~@/static/images/icons/chevron-right-black.svg');
            }
        }
    }
</style>
