<template>
    <section class="hero relative flex flex-col items-center justify-center">
        <picture v-if="backgroundImage.mobile">
            <source v-if="backgroundImage.desktop" :srcset="backgroundImage.desktop.url" media="(min-width: 768px)">
            <img
                :src="backgroundImage.mobile.url"
                :alt="backgroundImage.mobile.alt"
                class="absolute inset-0 w-full h-full object-cover object-center"
            >
        </picture>

        <video
            v-if="backgroundVideoUrl"
            :src="backgroundVideoUrl"
            class="absolute inset-0 w-full h-full object-cover object-center"
            autoplay
            loop
            muted
            playsinline
        ></video>

        <div v-if="darkBackground" class="absolute inset-0 w-full h-full bg-black opacity-50"></div>

        <div class="grid-container container relative pt-20 pb-10 lg:py-10">
            <div class="grid-row justify-center">
                <div class="grid-col w-full lg:w-2/3">
                    <div class="hero__content flex flex-col items-center text-center">
                        <div v-if="pillar.picto" class="hero__pillar relative flex flex-col items-center mb-8 text-center">
                            <img :src="pillar.picto.url" :alt="pillar.picto.alt">
                            <p class="font-bold text-white uppercase text-xs lg:text-base">{{ pillar.heading }}</p>

                            <img src="~@/static/images/fill-white.svg" alt="fill" class="mt-4">
                        </div>

                        <h1 class="text-white">{{ heading }}</h1>
                        <div v-html="description" class="hero__description text-white mt-3"></div>
                    </div>
                </div>
            </div>

        </div>

        <div class="absolute top-0 left-0 mt-5 w-full px-4">
            <div class="grid-container container flex justify-center lg:justify-start">
                <a :href="backButton.url" class="hero__back-button btn btn--ghost-white flex items-center">
                    {{ backButton.title }}
                </a>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "Hero",

        props: {
            pillar: {
                type: Object,
                default: null,
            },

            heading: {
                type: String,
                required: true,
            },

            description: {
                type: String,
                required: true,
            },

            backgroundVideoUrl: {
                type: String,
                required: true,
            },

            backgroundImage: {
                type: Object,
                required: true,
            },

            backButton: {
                type: Object,
                required: true,
            },

            darkBackground: {
                type: Boolean,
                required: true,
            },
        },
    }
</script>

<style scoped lang="scss">
    @import "../../assets/styles/base/typography";

    .hero {
        min-height: calc(100vh - 3.5rem);

        &__pillar {
            width: 10.875rem;

            @screen lg {
                width: 12.125rem;
            }
        }

        &__description {
            /deep/ p {
                @extend %lead;
            }
        }

        &__back-button {
            @apply px-5;

            &::before {
                content: '';
                @apply w-4 h-3 mr-3 bg-cover bg-center;
                background-image: url('~@/static/images/icons/back.svg');
            }
        }

        @screen lg {
            min-height: 80vh;
        }
    }
</style>
