<template>
    <section class="home-video relative overflow-hidden">
        <div
            v-if="videoCover && !isVideoPlayed"
            class="absolute z-10 flex flex-col justify-center items-center w-full h-full bg-center bg-cover"
            :style="`background-image: url('${videoCover.url}')`"
        >
            <button class="play-button" @click="playVideo">Play</button>
            <p v-if="videoTitle" class="font-bold text-white text-center uppercase text-xs lg:text-base mt-2 lg:mt-6" v-html="videoTitle"></p>
        </div>
        <video
            :src="videoUrl"
            class="w-full"
            controls
            ref="video"
        ></video>
    </section>
</template>

<script>
    export default {
        name: "HomeVideo",

        props: {
            videoUrl: {
                type: String,
                required: true,
            },
            videoCover: {
                type: Object,
                default: null,
            },
            videoTitle: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                isVideoPlayed: false,
            }
        },

        methods: {
            playVideo() {
                this.isVideoPlayed = true;
                this.$refs.video.play();
            },
        },
    }
</script>

<style scoped lang="scss">
    .home-video {

    }
</style>
