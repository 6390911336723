import "./assets/styles/main.scss";

require('typeface-open-sans');

import VueYoutube from 'vue-youtube';
Vue.use(VueYoutube);

import Vue from "vue";
import Hero from "@/app/components/Hero";
import CardCarousel from "@/app/components/CardCarousel";
import MediaCarousel from "@/app/components/MediaCarousel";
import HeaderNavbar from "@/app/components/HeaderNavbar";
import HomeVideo from "@/app/components/HomeVideo";

new Vue({
    el: "#app",
    components: {
        Hero,
        CardCarousel,
        MediaCarousel,
        HeaderNavbar,
        HomeVideo,
    },
});
