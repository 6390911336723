<template>
    <div class="card-carousel grid-container container">
        <div class="card-carousel__container -mx-2 md:-mx-4">
            <carousel
                :per-page-custom="[[320, 1], [768, 2], [1200, 3]]"
                :pagination-enabled="false"
                :navigation-enabled="true"
                :scroll-per-page="false"
            >
                <slide
                    v-for="(card, key) in cards"
                    :key="key"
                    class="px-2 md:px-4"
                >
                    <card
                        :card="card"
                    ></card>
                </slide>
            </carousel>
        </div>
    </div>
</template>

<script>
    import {Carousel, Slide} from 'vue-carousel';
    import Card from './Card';

    export default {
        name: "CardCarousel",

        props: {
            cards: {
                type: Array,
                required: true,
            }
        },

        components: {
            Carousel,
            Slide,
            Card,
        },
    }
</script>

<style scoped lang="scss">
    .card-carousel {
        &__container {
            width: 15.25rem;

            @screen md {
                width: auto;
            }
        }

        /deep/ .VueCarousel-wrapper {
            overflow: visible;
        }

        /deep/ .VueCarousel-navigation-button {
            width: 3.75rem;
            height: 3.75rem;
            @apply hidden rounded-full bg-white;
            box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.12);

            @screen md {
                @apply flex items-center justify-center;
            }

            &::after {
                content: '';
                @apply w-6 h-6 bg-cover bg-center bg-no-repeat;
            }

            &:focus {
                outline: none;
            }
        }

        /deep/ .VueCarousel-navigation--disabled {
            @apply hidden;
        }

        /deep/ .VueCarousel-navigation-next {
            font-size: 0;

            transform: translateY(-50%) translateX(50%);

            &::after {
                margin-right: 0.375rem;
                background-image: url('~@/static/images/icons/chevron-right-blue.svg');
            }
        }

        /deep/ .VueCarousel-navigation-prev {
            font-size: 0;

            transform: translateY(-50%) translateX(-50%);

            &::after {
                margin-left: 0.375rem;
                background-image: url('~@/static/images/icons/chevron-left-blue.svg');
            }
        }
    }
</style>
