<template>
    <a :href="card.url" class="card relative overflow-hidden flex flex-col justify-end bg-cover bg-center rounded-md">
        <div class="card__background absolute inset-0 bg-cover bg-center" :style="`background-image: url('${card.image.url}')`"></div>

        <div class="card__text-container relative flex flex-col items-center px-4 py-10 lg:px-16 lg:py-14">
            <img
                v-if="card.pillarPicto"
                :src="card.pillarPicto.url"
                alt="Logo pilier"
                class="card__pillar-picto absolute"
            >

            <p class="card__text relative font-bold text-white text-sm lg:text-base uppercase text-center">
                {{ card.heading }}
            </p>

            <div class="card__button-container relative">
                <button class="btn btn--ghost-dark mt-4 focus:outline-none">
                    En savoir plus
                </button>
            </div>
        </div>
    </a>
</template>

<script>
    export default {
        name: "Card",

        props: {
            card: {
                type: Object,
                required: true,
            },
        }
    }
</script>

<style scoped lang="scss">
    .card {
        height: 26.25rem;
        transition: box-shadow 250ms;

        @screen lg {
            height: 33.125rem;
        }

        &__background {
            transition: transform 250ms;

            &::after {
                content: '';
                @apply absolute inset-0 w-full h-full;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 66%, rgba(0, 0, 0, 0.75) 100%);
            }
        }

        &__pillar-picto {
            top: -2rem;
            width: 4.875rem;
            height: 4.875rem;
            transition: opacity 250ms;
        }

        &__text {
            transition: color 250ms;
        }

        &__text-container {
            &::before {
                content: '';
                @apply bg-white absolute w-full h-0 left-0 right-0 bottom-0;
                transition: height 250ms;
            }
        }

        &__button-container {
            height: 0;
            opacity: 0;
            transition: height 250ms, opacity 250ms;
        }

        &:hover {
            box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.22);

            .card__background {
                transform: translateY(-2.5rem);
            }

            .card__pillar-picto {
                opacity: 0;
            }

            .card__text-container {
                &::before {
                    @apply h-full;
                }
            }

            .card__text {
                @apply text-black;
            }

            .card__button-container {
                opacity: 1;
                height: 67px;
            }
        }
    }
</style>
